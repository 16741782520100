@charset "UTF-8";
/*
 * Web Fonts from fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2015 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      exljbris Font Foundry
 * License URL: http://www.fontspring.com/licenses/exljbris/webfont
 *
 *
 */
@font-face {
    font-family: 'museo-slab';
    src: url("../fonts/museo-slab/Museo_Slab_500_2-webfont.eot");
    src: url("../fonts/museo-slab/Museo_Slab_500_2-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/museo-slab/Museo_Slab_500_2-webfont.woff2") format("woff2"), url("../fonts/museo-slab/Museo_Slab_500_2-webfont.woff") format("woff"), url("../fonts/museo-slab/Museo_Slab_500_2-webfont.ttf") format("truetype"), url("../fonts/museo-slab/Museo_Slab_500_2-webfont.svg#museo-slab") format("svg");
    font-weight: normal;
    font-style: normal; }





/*------------------------------------*\
    #HEADER
\*------------------------------------*/
.header {}
.header:before, .header:after {
    content: " ";
    display: table; }
.header:after {
    clear: both; }
@media (max-width: 767px) {
    .header {
        display: none; }
}
.header .header-left {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 15px; }
@media (min-width: 768px) {
    .header .header-left {
        float: left;
        width: 33.3333333333%; } }
.header .header-right {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px; }
/*@media (min-width: 768px) {
    .header .header-right {
        float: left;
        width: 66.6666666667%; } }*/
.header .header-right .navbar-nav > li > a {
    padding-bottom: 5px; }
.header .header-right .navbar-nav > li > a:hover {
    color: #5f5a56; }
.header .header-right .top-menu,
.header .header-right .focus-tag,
.header .header-right .search-form {
    float: right;
    clear: both; }
.header .header-right .search-form {
    margin-top: 15px;
    margin-bottom: 15px; }
.header .header-right .search-form #search {
    min-width: 200px;
    height: 28px; }
.header .header-right .search-form .search-submit {
    color: #fff;
    background-color: #5f5a56;
    border-color: #54504c;
    border-radius: 45px; }
.header .header-right .search-form .search-submit:focus, .header .header-right .search-form .search-submit.focus {
    color: #fff;
    background-color: #44413e;
    border-color: #111010; }
.header .header-right .search-form .search-submit:hover {
    color: #fff;
    background-color: #44413e;
    border-color: #34312f; }
.header .header-right .search-form .search-submit:active, .header .header-right .search-form .search-submit.active,
.open > .header .header-right .search-form .search-submit.dropdown-toggle {
    color: #fff;
    background-color: #44413e;
    border-color: #34312f; }
.header .header-right .search-form .search-submit:active:hover, .header .header-right .search-form .search-submit:active:focus, .header .header-right .search-form .search-submit:active.focus, .header .header-right .search-form .search-submit.active:hover, .header .header-right .search-form .search-submit.active:focus, .header .header-right .search-form .search-submit.active.focus,
.open > .header .header-right .search-form .search-submit.dropdown-toggle:hover,
.open > .header .header-right .search-form .search-submit.dropdown-toggle:focus,
.open > .header .header-right .search-form .search-submit.dropdown-toggle.focus {
    color: #fff;
    background-color: #312f2d;
    border-color: #111010; }
.header .header-right .search-form .search-submit:active, .header .header-right .search-form .search-submit.active,
/*.open > .header .header-right .search-form .search-submit.dropdown-toggle {
    background-image: ; }*/
.header .header-right .search-form .search-submit.disabled:hover, .header .header-right .search-form .search-submit.disabled:focus, .header .header-right .search-form .search-submit.disabled.focus, .header .header-right .search-form .search-submit[disabled]:hover, .header .header-right .search-form .search-submit[disabled]:focus, .header .header-right .search-form .search-submit[disabled].focus,
fieldset[disabled] .header .header-right .search-form .search-submit:hover,
fieldset[disabled] .header .header-right .search-form .search-submit:focus,
fieldset[disabled] .header .header-right .search-form .search-submit.focus {
    background-color: #5f5a56;
    border-color: #54504c; }
.header .header-right .search-form .search-submit .badge {
    color: #5f5a56;
    background-color: #fff; }





/*------------------------------------*\
    #MAIN NAV
\*------------------------------------*/
.main-nav {
    margin-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px dotted #d5d1c5; }
.main-nav .navbar-brand {
    color: #5f5a56;
    font-family: "museo-slab", serif;
    font-size: 20px; }
.main-nav .main-menu > .main-menu__item > a {
    color: #5f5a56;
    font-family: "museo-slab", serif; }
.main-nav .main-menu > .main-menu__item.open > a {
    padding-top: 12px;
    background-color: transparent;
    border: 1px solid transparent;
    /*border-top: 3px solid transparent;*/
    /*border-bottom: ; */
  }
@media (min-width: 992px) {
    .main-nav .main-menu > .main-menu__item > a:hover, .main-nav .main-menu > .main-menu__item > a.active {
        padding-top: 12px;
        background-color: transparent;
        border-top: 3px solid transparent; }
    .main-nav .main-menu > .main-menu__item:nth-of-type(1) > a:hover, .main-nav .main-menu > .main-menu__item:nth-of-type(1) > a.active, .main-nav .main-menu > .main-menu__item:nth-of-type(1).open > a {
        border-color: #929c43; }
    .main-nav .main-menu > .main-menu__item:nth-of-type(2) > a:hover, .main-nav .main-menu > .main-menu__item:nth-of-type(2) > a.active, .main-nav .main-menu > .main-menu__item:nth-of-type(2).open > a {
        border-color: #ce7503; }
    .main-nav .main-menu > .main-menu__item:nth-of-type(3) > a:hover, .main-nav .main-menu > .main-menu__item:nth-of-type(3) > a.active, .main-nav .main-menu > .main-menu__item:nth-of-type(3).open > a {
        border-color: #4fc8a9; }
    .main-nav .main-menu > .main-menu__item:nth-of-type(4) > a:hover, .main-nav .main-menu > .main-menu__item:nth-of-type(4) > a.active, .main-nav .main-menu > .main-menu__item:nth-of-type(4).open > a {
        border-color: #ca0e00; } }
@media (min-width: 992px) {
    .main-nav .main-menu {
        margin-left: -30px; } }
.main-nav .navbar-toggle {
    background-color: #ddd;
    border-color: #ddd; }
.main-nav .navbar-toggle .icon-bar {
    background-color: #888; }

.end-the-wait > li > a {
    color: #fff;
    background-color: #ca0e00;
    font-family: "museo-slab", serif; }
.end-the-wait > li > a:hover {
    background-color: #000; }

@media (min-width: 992px) {
    .end-the-wait {
        margin-right: -30px; } }

@media (min-width: 768px) {
    .navbar-brand {
        display: none; } }

@media (max-width: 767px) {
    .main-nav {
        min-height: 82px; }
    .navbar-brand .logo {
        max-height: 50px; } }











/*------------------------------------*\
    #SIDEBAR
\*------------------------------------*/
.sidebar {
    /*position: relative;*/
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    border-left: 1px dotted #d5d1c5;
  }

/* Remove left border when sidebar wraps below article */
@media (max-width: 991px) {
    .sidebar {
      border-left: none; }
}

.sidebar .sidebar__box {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dotted #d5d1c5; }
.sidebar .sidebar__box:last-of-type {
    border-bottom: none; }
.sidebar h2, .sidebar h3 {
    font-family: "Neucha", cursive; }
.sidebar blockquote {
    padding: 0;
    margin: 0;
    border-left: none;
    color: #bbb5a2;
    margin-bottom: 15px;
    font-family: "Neucha", cursive; }

.sidebar blockquote p {
    font-size: 23px;
    line-height: 1.2;
    color: #bbb5a2;
}

.sidebar blockquote cite {
  font-size: 16px;
  letter-spacing: 2px;
}

/*@media (max-width: 992px) {
    .sidebar {
        clear: both;
        padding-top: 40px; } }*/





/*------------------------------------*\
    #WHATS NEW
\*------------------------------------*/
.whats-new-module li {
    margin-bottom: 15px; }

.whats-new-module .article__title {
    margin-top: 0px;
    margin-bottom: 8px;
    color: #929c43; }

.whats-new-module .article__subtitle {
    color: #5f5a56; }





/*------------------------------------*\
    #SIGNUP FORM
\*------------------------------------*/
.signup-form .form-embed .hs-form-field > label {
    display: none !important; }

.signup-form .form-embed .hs-form.stacked .actions {
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-left: -20px !important; }
.signup-form .form-embed .hs-form.stacked .actions input.hs-button.primary.large {
    text-shadow: none !important;
    background: #5f5a56 none !important;
    box-shadow: none !important;
    border-color: #54504c !important; }
.signup-form .form-embed .hs-form.stacked .actions input.hs-button.primary.large:hover {
    background-color: #ce7503 !important;
    border-color: #ba6902 !important; }

.signup-form .form-embed .hs-form.stacked .field {
    margin-bottom: 15px !important; }

.signup-form .form-embed .hs-form.stacked .hs-error-msgs {
    padding-bottom: 5px; }
.signup-form .form-embed .hs-form.stacked .hs-error-msgs > li {
    position: absolute !important; }
.signup-form .form-embed .hs-form.stacked .hs-error-msgs > li label {
    color: #c87872 !important; }

/*------------------------------------*\
    #AUTHOR INFO
\*------------------------------------*/
.author-bio {
  margin: 30px 0 0 0;
}



/*------------------------------------*\
    #END THE WAIT
\*------------------------------------*/
.ETW--intro h2, .ETW--intro h3,
.ETW--content h2, .ETW--content h3 {
    font-family: "Arvo", serif;
}
.ETW--intro h2, .ETW--content h2 {
    color: #ca0e00;
}
.ETW--intro h2 {
    font-weight: bold;
    font-weight: 500;
    font-size: 30px;
    line-height: 24px;
}
.ETW--intro strong {
    color: #ca0e00;
}
.ETW--content h3, .ETW--content a {
    color: #5f5a56;
}
.ETW--content a .more-link {
    color: #ca0e00;
}
.how-to-help a.click-more {
    display: block;
    text-decoration: none;
    transition: background-color 300ms ease-in;
}
.how-to-help a.click-more:hover {
    text-decoration: none;
    background-color: #efefef;
}
.how-to-help .number-wrapper,
.how-to-help .click-more,
.how-to-help .more {
    padding: 10px;
}
.how-to-help .opened {

}
.how-to-help .number {
    padding: 5px;
    text-align: center;
    color: #fff;
    background-color: #ca0e00;
}
.how-to-help .number-wrapper h3,
.how-to-help .click-more h3 {
    margin-top: 0px;
}
.how-to-help .more h3 {
    font-size: 20px;
}
.how-to-help .more {
    border-top: 1px dotted #d5d1c5;
    background-color: #ebebeb;
}

// SUPER HACKY!
.list-margin li {
  margin: 18px 0;
}





/*------------------------------------*\
    #MAIN CONTENT
\*------------------------------------*/
/*.Main__content {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
@media (min-width: 992px) {
    .Main__content {
        float: left;
        width: 66.6666666667%; } }*/





/*------------------------------------*\
    #HOME CONTENT
\*------------------------------------*/
.home-content {
    padding-bottom: 30px; }
.home-content:before, .home-content:after {
    content: " ";
    display: table; }
.home-content:after {
    clear: both; }
.home-content .home-content__video {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px; }
/*@media (min-width: 992px) {
    .home-content .home-content__video {
        float: left;
        width: 66.6666666667%; } }*/
.home-content .home-content__menu {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px; }
/*@media (min-width: 992px) {
    .home-content .home-content__menu {
        float: left;
        width: 33.3333333333%; } }*/
.home-content .home-content__menu .menu-wrapper {
    padding: 49px 15px;
    background-color: #5f5a56; }
.home-content .home-content__menu .list-group-item {
    color: #d5d1c5;
    background-color: #5f5a56;
    border-top: 1px solid #d5d1c5;
    border-bottom: 1px solid #d5d1c5;
    border-left: none;
    border-right: none;
    border-radius: 0px; }
.home-content .home-content__menu .list-group-item:hover {
    background-color: #54504c;
    color: #fff; }

.home-about {
    margin-bottom: 15px; }
.home-about:before, .home-about:after {
    content: " ";
    display: table; }
.home-about:after {
    clear: both; }
.home-about .about-heading {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 3rem;
    margin-top: 15px;
    font-family: "Neucha", cursive; }
/*@media (min-width: 992px) {
    .home-about .about-heading {
        float: left;
        width: 33.3333333333%; } }*/
.home-about .about-text {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    line-height: 1.5; }
/*@media (min-width: 992px) {
    .home-about .about-text {
        float: left;
        width: 66.6666666667%; } }*/





/*------------------------------------*\
    #JOIN THE CONVERSATION

    Note: Current site configuration does not use
    any of these tags!

\*------------------------------------*/
/*.conversation {}
.conversation:before, .conversation:after {
    content: " ";
    display: table; }
.conversation:after {
    clear: both; }
.conversation .title {
    margin-top: 0px;
    font-family: "Neucha", cursive; }
.conversation a {
    color: #5f5a56; }
.conversation a:hover {
    color: #54504c; }
.conversation .heading {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px; }
@media (min-width: 992px) {
    .conversation .heading {
        float: left;
        width: 100%; } }
.conversation .whats-new {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px; }
@media (min-width: 992px) {
    .conversation .whats-new {
        float: left;
        width: 25%; } }
.conversation .signup {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px; }
@media (min-width: 992px) {
    .conversation .signup {
        float: left;
        width: 25%; } }
.conversation .ad-space {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px; }
@media (min-width: 992px) {
    .conversation .ad-space {
        float: left;
        width: 50%; } }*/
/* partials/media/image.blade has img-responsive class applied */
/*.conversation .ad-space img {
    max-width: 100%; }*/





/*------------------------------------*\
    #CATEGORY
\*------------------------------------*/
section.category {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
/*@media (min-width: 992px) {
    section.category {
        float: left;
        width: 66.6666666667%; } }*/

section.category .featured {
  margin-top: 15px;
  margin-bottom: 15px; }

section.category .description {
  margin-top: 20px; }
section.category .subcategories {}
section.category .subcategories:before, section.category .subcategories:after {
    content: " ";
    display: table; }
section.category .subcategories:after {
    clear: both; }
section.category .subcategories .subcategory {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
/*@media (min-width: 992px) {
    section.category .subcategories .subcategory {
        float: left;
        width: 33.3333333333%; } }*/
section.category .subcategories .subcategory .title a, section.category .subcategories .subcategory .view-more a {
    color: #5f5a56 !important; }
section.category .subcategories .subcategory .title a:hover, section.category .subcategories .subcategory .view-more a:hover {
    color: #5f5a56 !important; }
section.category .subcategories .subcategory .article__list {
    padding-left: 0;
    list-style: none; }
section.category .subcategories .subcategory .article__list li {
    margin-bottom: 8px; }

section.subcategory {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px; }
/*@media (min-width: 992px) {
    section.subcategory {
        float: left;
        width: 66.6666666667%; } }*/
section.subcategory .description:before, section.subcategory .description:after {
    content: " ";
    display: table; }
section.subcategory .description:after {
    clear: both; }
section.subcategory .description .text {
    margin-top: 15px; }
section.subcategory .description .media {
    margin-top: 15px; }
section.subcategory .description .media.image {
    float: right;
    margin-left: 30px; }
section.subcategory .featured-articles {
    padding-top: 15px;
    margin-bottom: 15px; }
section.subcategory .featured-articles:before, section.subcategory .featured-articles:after {
    content: " ";
    display: table; }
section.subcategory .featured-articles:after {
    clear: both; }
section.subcategory .featured-articles .column {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
@media (min-width: 992px) {
    section.subcategory .featured-articles .column {
        float: left;
        width: 50%; } }
section.subcategory .featured-articles .column:first-of-type {
    border-right: 1px dotted #d5d1c5; }
section.subcategory .featured-articles .one a {
    color: #929c43; }
section.subcategory .featured-articles .two a {
    color: #ce7503; }
section.subcategory .featured-articles .three a {
    color: #4fc8a9; }
section.subcategory .featured-articles .four a {
    color: #ca0e00; }
section.subcategory .featured-articles .article {
    display: block;
    margin-bottom: 15px; }
section.subcategory .featured-articles .article:hover {
    text-decoration: none; }
section.subcategory .featured-articles .article .title {
    margin-top: 0px;
    color: #5f5a56;
    font-family: "Arvo", serif;
    font-size: 20px; }
section.subcategory .featured-articles .article .read-more {
    color: #5f5a56; }
section.subcategory .articles {
    padding-top: 15px;
    margin-bottom: 15px; }
section.subcategory .articles .articles__list {
    padding-left: 0;
    list-style: none; }
section.subcategory .articles .articles__list__item {
    border-top: 1px solid #d5d1c5; }
section.subcategory .articles .articles__list__item .title {
    font-size: 16px; }
section.subcategory .articles .articles__list__item .subtitle {
    text-transform: uppercase; }




/*------------------------------------*\
    #ARTICLE
\*------------------------------------*/
section.article {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
/*@media (min-width: 992px) {
    section.article {
        float: left;
        width: 66.6666666667%; } }*/


section.article .title {
    font-family: "Arvo", serif;
    font-size: 41px; }


section.article .subtitle {
    font-size: 26px;
    font-family: "Neucha", cursive; }


section.article .meta {
    font-size: 14px;
    color: #d5d1c5;
    text-transform: uppercase; }


section.article .author_info {
    font-style: italic;
    color: #d5d1c5;
    padding-top: 15px;
    margin-bottom: 15px; }


section.article .copyright,
section.article .disclaimer {
  font-size: 14px;
  line-height: 14px;
  margin-top: 14px;
  color: #d5d1c5; }


section.article .pull-quote {
    width: 200px;
    float: right;
    clear: right;
    margin: 4px 0 15px 30px;
    padding: 17px 0 20px 0;
    font-size: 18px;
    font-family: "Neucha", cursive;
    line-height: 24px;
    border-top: 1px solid #d5d1c5;
    border-bottom: 1px solid #d5d1c5; }

section.article .pull-quote:before {
    content: "“"; }

section.article .pull-quote:after {
    content: "”"; }


section.article .footnotes {
    margin-top: 30px;
    border-top: 1px solid #d5d1c5;
    padding-top: 8px;
    font-size: 12px;
}


section.article .body .image {
  float: right;
  margin: 0 0 15px 15px;
  max-width: 200px;
}




section.article .tags-list {
    padding-top: 15px; }
section.article .tags-list a {
    color: #fff; }
section.article .tags-list a:hover {
    color: #fff; }
section.article .related {
    padding-top: 15px;
    margin-bottom: 15px; }
section.article .related:before, section.article .related:after {
    content: " ";
    display: table; }
section.article .related:after {
    clear: both; }
section.article .related .heading {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px; }
@media (min-width: 768px) {
    section.article .related .heading {
        float: left;
        width: 100%; } }
section.article .related .heading h3 {
    margin: 15px 0px 0px 0px;
    padding-bottom: 15px;
    border-bottom: 8px solid #d5d1c5;
    font-family: "Arvo", serif; }
section.article .related .related__articles {
    clear: both;
    padding-top: 15px; }
section.article .related .related__articles:before, section.article .related .related__articles:after {
    content: " ";
    display: table; }
section.article .related .related__articles:after {
    clear: both; }
section.article .related .related__articles .featured {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    border-right: 1px dotted #d5d1c5; }
@media (min-width: 992px) {
    section.article .related .related__articles .featured {
        float: left;
        width: 50%; } }
section.article .related .related__articles .featured .title {
    margin: 0;
    font-family: "Arvo", serif;
    font-size: 18px; }
section.article .related .related__articles .featured .subtitle {
    font-family: "Arvo", serif;
    text-transform: uppercase;
    font-size: 14px; }
section.article .related .related__articles .related__list {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
@media (min-width: 992px) {
    section.article .related .related__articles .related__list {
        float: left;
        width: 50%; } }
section.article .related .related__articles .related__list ul {
    padding-left: 0;
    list-style: none; }
section.article .related .related__articles .related__list ul li {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #d5d1c5; }
section.article .related .related__articles .related__list ul li:first-of-type {
    border-top: 1px solid #d5d1c5; }
section.article .related .related__articles .related__list ul li a {
    color: #5f5a56 !important; }





/*------------------------------------*\
    #FAQ
\*------------------------------------*/
.FAQ .faq-question-answer {
    border-bottom: 1px solid #d5d1c5;
    padding: 10px 0px; }

.FAQ .faq-question {
    display: block; }
.FAQ .faq-question:before {
    content: "> ";
    text-decoration: none;
    color: #666; }

.FAQ .faq-answer {
    display: none;
    margin-top: 10px; }

.search {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
/*@media (min-width: 992px) {
    .search {
        float: left;
        width: 66.6666666667%; } }*/





/*------------------------------------*\
    #SEARCH RESULTS
\*------------------------------------*/
.search__result__list {
    margin-bottom: 30px; }
.search__result__list .highlight {
    background-color: #ffcaa4; }

.search__result__item {
    margin-top: 30px; }
.search__result__item a {
    display: block;
    color: inherit; }

/*------------------------------------*\
    #UTILITY CLASSES
\*------------------------------------*/
.top-border {
    border-top: 1px dotted #d5d1c5; }

.bottom-border {
    border-bottom: 1px dotted #d5d1c5; }

.left-border {
    border-left: 1px dotted #d5d1c5; }

.right-border {
    border-right: 1px dotted #d5d1c5; }

/* Remove side borders when sidebar wraps below article */
@media (max-width: 991px) {
    .left-border,
    .right-border {
      border: none; }
}

/* Adding a dot between nav links */
#nav-utility:before {
  content:'\00B7';
  padding-right: 30px; }

.ad-space {
  margin-bottom: 20px;
}


/*------------------------------------*\
    #RYANS STYLES
\*------------------------------------*/
body {
    color: #5f5a56;
    font-family: "Arvo", serif; }

a {
    color: #929c43; }
a:hover {
    color: #5f5a56; }

h1, h2, h3, h4, .navbar-toggle {
    font-family: "museo-slab", serif; }

.rule {
    clear: both;
    margin-top: 15px;
    margin-bottom: 15px;
    border-bottom: 8px solid #d5d1c5; }

.one a, .title.one, .subtitle.one {
    color: #929c43; }

.two a, .title.two, .subtitle.two {
    color: #ce7503; }

.three a, .title.three, .subtitle.three {
    color: #4fc8a9; }

.four a, .title.four, .subtitle.four {
    color: #ca0e00; }

.one a:hover, .two a:hover, .three a:hover, .four a:hover {
    color: #292726; }

.one .featured-articles a.article:hover .title, .one .featured-articles a.article:hover .subtitle, .one .featured-articles a.article:hover .read-more, .two .featured-articles a.article:hover .title, .two .featured-articles a.article:hover .subtitle, .two .featured-articles a.article:hover .read-more, .three .featured-articles a.article:hover .title, .three .featured-articles a.article:hover .subtitle, .three .featured-articles a.article:hover .read-more, .four .featured-articles a.article:hover .title, .four .featured-articles a.article:hover .subtitle, .four .featured-articles a.article:hover .read-more {
    color: #292726;
}


/*------------------------------------*\
    #FOOTER
\*------------------------------------*/
footer {
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 30px;
    color: #c6bdae;
    background-color: #5f5a56; }
footer .column {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    margin-bottom: -99999px;
    padding-bottom: 99999px; }
@media (min-width: 992px) {
    footer .column {
        float: left;
        width: 25%; } }
footer .column .title {
    color: #fff;
    font-family: "Arvo", serif;
    font-size: 18px; }
footer a:hover {
    color: #fff; }
footer .top-questions li {
    margin-bottom: 8px; }
footer .top-questions .q-abbr {
    color: #fff; }
footer .links li {
    line-height: 1.5; }
footer .find-out-more li {
    line-height: 1.5; }
footer .find-out-more .copyright {
    font-size: 13px; }
/*
 * Hack to allow equal height columns. See the
 * crazy margin and padding on footer.column above.
 */
footer #column-wrap {
    overflow: hidden;
}
